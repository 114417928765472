import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormRow, FormRowSelect } from '.';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/FileUpload';

const Spoilage = () => {
  const navigate = useNavigate();

  const { isLoading, getSpoilage } = useAppContext();

  const currentYear = new Date().getFullYear();

  const getCurrentMonth = () => {
    const months = [
      'All',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentMonth = months[currentMonthIndex];

    return currentMonth;
  };

  function getYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 2025;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  }

  const yearsArray = getYearsArray();

  const [formData, setFormData] = useState({
    month: getCurrentMonth(),
    year: currentYear.toString(),
    searchType: 'Sales By Machine',
    adminReviewed: false,
    machineName: '',
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log('Form submitted:', formData);
    getSpoilage(
      formData.month,
      formData.year,
      //formData.searchType,
      formData.adminReviewed,
      formData.machineName,
    );

    navigate('/spoilage');
  };

  return (
    <Wrapper>
      <form className='form' onSubmit={onSubmit}>
        <h4>search spoilage</h4>
        <div className='form-center'>
          {/* month */}
          <FormRowSelect
            name='month'
            value={formData.month}
            handleChange={handleInputChange}
            list={[
              'All',
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ]}
          ></FormRowSelect>
          {/* year */}
          <FormRowSelect
            name='year'
            value={formData.year}
            handleChange={handleInputChange}
            list={yearsArray}
          ></FormRowSelect>
          {/* search type
          <FormRowSelect
            labelText='search type'
            name='searchType'
            value={formData.searchType}
            handleChange={handleInputChange}
            list={[
              'Sales By Machine',
              'Sales By Product By Machine',
              'Sales By Product',
            ]}
          ></FormRowSelect> */}
          {/* machine name */}
          <div className='form-row'>
            <FormRow
              type='text'
              name='machineName'
              value={formData.machineName}
              handleChange={handleInputChange}
            ></FormRow>
          </div>
          {/* admin reviewed checkbox */}
          <div className='form-row'>
            <FormRow
              type='checkbox'
              name='adminReviewed'
              value={formData.adminReviewed}
              handleChange={handleInputChange}
            ></FormRow>
          </div>
          <button
            className='btn btn-block btn-danger'
            disabled={isLoading}
            onClick={onSubmit}
          >
            Get Report
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default Spoilage;

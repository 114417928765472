import styled from 'styled-components';

const Wrapper = styled.article`
  background: var(--white);
  border-radius: var(--borderRadius);
  display: grid;
  grid-template-rows: 1fr;
  box-shadow: var(--shadow-2);
  width: 1fr;
  height: 70px;
  margin: 5px;

  header {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    h5 {
      letter-spacing: 0;
    }
  }
  .main-icon {
    width: 100%;
    height: 25px;
    display: grid;
    place-items: center;
    background: var(--primary-500);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    margin-right: 2rem;
  }

  .info {
    padding-top: 5px;
    text-align: center;
    line-height: 1.2;
  }

  .needs-fill {
    background: #ff0000;
  }

  .filled {
    background: #ffa500;
  }

  .no-stock {
    background: #000000;
  }

  &:hover {
    border: 2px solid red;
  }

  @media (max-width: 576px) {
    .info {
      font-size: 12px;
    }
  }
`;

export default Wrapper;

import React, { useEffect } from 'react';
import Alert from '../../components/Alert';
import { useAppContext } from '../../context/appContext';
import FileUpload from '../../components/FileUpload';
import Transaction from '../../components/Transactions';
import Wrapper from '../../assets/wrappers/Dashboard';

import {
  Loading,
  ChartsContainer,
  DailyTransactions,
  StockedAndDelivered,
  ErrorReport,
  StockerDashboard,
  DriverDashboard,
  AddMessage,
  ViewMessageComponent,
  MonthlyTransactions,
  AlertItem,
  Spoilage,
} from '../../components';

const Dashbaord = () => {
  const {
    showAlert,
    isLoading,
    showFileUpload,
    toggleFileUpload,
    toggleShowMessage,
    getAllMonthlyTransactions,
    monthlyTransactions,
    user,
    showMessage,
    getUsers,
    users,
    getMessages,
    messages,
    totalMessages,
    totalUnredMessages,
    getStockedAndDelivered,
    needsStock,
    stocked,
    runStockCheck,
    showMonthlyTransactions,
    showSpoilage,
    toggleMonthlyTransactions,
    toggleSpoilage,
    getAlerts,
    alerts,
  } = useAppContext();

  const id = user._id;

  useEffect(() => {
    getAllMonthlyTransactions();
    getUsers();
    getMessages(id);
    getStockedAndDelivered();
    getAlerts();
  }, [id]);

  if (isLoading) {
    return <Loading center />;
  }

  if (user.role === 'stocker') {
    return (
      <>
        <h3>Welcome {user.name}</h3>
        {totalUnredMessages > 0 ? (
          <>
            <h4>Messages</h4>
            <ViewMessageComponent
              messages={messages}
              totalMessages={totalMessages}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <StockerDashboard needsStock={needsStock} />
      </>
    );
  }

  if (user.role === 'driver') {
    return (
      <>
        <h3>Welcome {user.name}</h3>
        {totalUnredMessages > 0 ? (
          <>
            <h4>Messages</h4>
            <ViewMessageComponent
              messages={messages}
              totalMessages={totalMessages}
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <DriverDashboard needsDelivered={stocked} />
      </>
    );
  }

  if (user.role === 'stocker-driver') {
    return (
      <>
        <h3>Welcome {user.name}</h3>
        {totalUnredMessages > 0 ? (
          <>
            <h4>Messages</h4>
            <ViewMessageComponent
              messages={messages}
              totalMessages={totalMessages}
            />
            <br />
          </>
        ) : (
          <></>
        )}

        <StockerDashboard needsStock={needsStock} />
        <br />
        <DriverDashboard needsDelivered={stocked} />
      </>
    );
  }

  return (
    <Wrapper>
      {showAlert && <Alert />}
      <div className='action-center'>
        <h4>ACTION CENTER</h4>
        <button
          type='button'
          className={showFileUpload ? 'btn hide-btn' : 'btn view-btn'}
          onClick={() => toggleFileUpload()}
        >
          {showFileUpload ? 'Hide File Upload' : 'View File Upload'}
        </button>
        <button
          type='button'
          className={showMessage ? 'btn hide-btn' : 'btn view-btn'}
          onClick={() => toggleShowMessage()}
        >
          {showMessage ? 'Hide Messages' : 'View Messages'}
        </button>
        <button
          type='button'
          className='btn view-btn'
          onClick={() => runStockCheck()}
        >
          Run Stock Check
        </button>
      </div>

      <br />
      {alerts.length > 0 && (
        <div className='alert-center'>
          <h4>Alerts</h4>

          {alerts.map((alert, index) => (
            <AlertItem key={index} alert={alert} />
          ))}
        </div>
      )}

      <br></br>

      {showFileUpload && (
        <>
          <FileUpload />
          <Transaction />
          <hr></hr>
        </>
      )}

      <br></br>

      {showMessage && (
        <>
          <h4>Add Message</h4>
          <AddMessage users={users} />
        </>
      )}

      <DailyTransactions />
      <ErrorReport />
      <br></br>
      <h4>Stocked and Delivered</h4>
      <StockedAndDelivered />
      <h2>Monthly Transactions</h2>
      <div className='action-center'>
        <h4>ACTION CENTER</h4>
        {/* Monthly Transactions */}
        <button
          type='button'
          className={showMonthlyTransactions ? 'btn hide-btn' : 'btn view-btn'}
          onClick={() => toggleMonthlyTransactions()}
        >
          {showMonthlyTransactions
            ? 'Hide Monthly Transactions'
            : 'View Monthly Transactions'}
        </button>
        {/* Spoilage */}
        <button
          type='button'
          className={showSpoilage ? 'btn hide-btn' : 'btn view-btn'}
          onClick={() => toggleSpoilage()}
        >
          {showSpoilage ? 'Hide Spoilage' : 'View Spoilage'}
        </button>
      </div>

      {showMonthlyTransactions && <MonthlyTransactions />}
      {showSpoilage && <Spoilage />}

      {/* Removed per request */}
      {/* <hr></hr>
      {monthlyTransactions.length > 0 && <ChartsContainer />} */}
    </Wrapper>
  );
};

export default Dashbaord;

import React from 'react';

import { useAppContext } from '../context/appContext';

import Wrapper from '../assets/wrappers/NotificationButton';

import MachineProductDriverSmall from './MachineProductDriverSmall';
import SelectedProductInfoContainer from './SelectedProductInfoContainer';
import SelectedAddNotesContainer from './SelectedAddNotesContainer';
import SelectedNotesContainer from './SelectedNotesContainer';

const DriverSelectedMachine = ({ machineProducts, machine }) => {
  const { selectedMachineName, selectedId } = machine;

  const {
    selectedColumns,
    updateMachineStatus,
    showMachineAddNote,
    showMachineNote,
    toggleMachineAddNote,
    toggleMachineNote,
    totalNotes,
  } = useAppContext();

  if (!machineProducts) {
    return (
      <>
        <h2>No products in machine...</h2>
      </>
    );
  }

  function compare(a, b) {
    if (a.location < b.location) {
      return -1;
    }
    if (a.location > b.location) {
      return 1;
    }
    return 0;
  }

  let productsSmall;

  switch (selectedColumns) {
    case 3:
      productsSmall = 'products-small-3';
      break;
    case 4:
      productsSmall = 'products-small-4';
      break;
    case 5:
      productsSmall = 'products-small-5';
      break;
    case 6:
      productsSmall = 'products-small-6';
      break;
    case 7:
      productsSmall = 'products-small-7';
      break;
    case 8:
      productsSmall = 'products-small-8';
      break;
    case 9:
      productsSmall = 'products-small-9';
      break;
    case 10:
      productsSmall = 'products-small-10';
      break;
  }

  return (
    <Wrapper>
      <h1>DRIVER: {selectedMachineName}</h1>
      <div>
        {/* Changed form delivered to vending */}
        <button
          type='button'
          className={'btn view-btn'}
          onClick={() => updateMachineStatus(selectedId, 'vending')}
        >
          Submit Machine
        </button>
        <button
          type='button'
          className={
            showMachineNote
              ? 'btn hide-btn notification-button'
              : 'btn view-btn notification-button'
          }
          onClick={() => toggleMachineNote()}
        >
          {showMachineNote ? 'Hide Notes' : 'View Notes'}
          {totalNotes > 0 && (
            <span className='notification-counter'>{totalNotes}</span>
          )}
        </button>
        {showMachineNote && (
          <button
            type='button'
            className='btn edit-btn'
            onClick={() => toggleMachineAddNote()}
          >
            {showMachineAddNote ? 'Close' : 'Add Note'}
          </button>
        )}
      </div>
      <br></br>

      {showMachineAddNote && <SelectedAddNotesContainer />}
      {showMachineNote && <SelectedNotesContainer />}

      {!showMachineNote && (
        <>
          <div className='sticky-component'>
            {<SelectedProductInfoContainer />}
            <br></br>
          </div>

          <div className={`${productsSmall} machine-product`}>
            {machineProducts.sort(compare).map((row) => {
              return <MachineProductDriverSmall key={row._id} {...row} />;
            })}
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default DriverSelectedMachine;

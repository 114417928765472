import React from 'react';
import Wrapper from '../assets/wrappers/MachineProductExtraSmall';
import { useAppContext } from '../context/appContext';
import moment from 'moment';

const MachineProduct = ({
  _id,
  productName,
  productType,
  location,
  price,
  sales,
  maxStock,
  currentStock,
  stocked,
  stockedDate,
  stockerReplaced,
  stockerReplacedProductName,
  stockerReplacedProductType,
  stockerReplacedProductPrice,
  stockerReplacedProductMaxStock,
  replaceFlag,
  row,
  column,
  rowPosition,
  columnPosition,
  independentPricing,
}) => {
  const { updateProductInfoView } = useAppContext();

  let date = moment(stockedDate);
  date = date.format('MMM Do, YYYY, h:mm:ss a ');

  const neededStock = maxStock - currentStock;

  let needsFill = false;

  if (currentStock <= maxStock * 0.5) {
    needsFill = true;
  }

  const handleClick = () => {
    updateProductInfoView(
      _id,
      productName,
      productType,
      location,
      price,
      sales,
      maxStock,
      currentStock,
      stocked,
      stockedDate,
      stockerReplaced,
      stockerReplacedProductName,
      stockerReplacedProductType,
      stockerReplacedProductPrice,
      stockerReplacedProductMaxStock,
      replaceFlag,
      independentPricing,
    );
  };

  return (
    <Wrapper>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        <header>
          {maxStock === 0 ? (
            <div className={'main-icon no-stock'}>{location}</div>
          ) : stocked > 0 ? (
            <div className={'main-icon filled'}>{location}</div>
          ) : (
            <div className={needsFill ? 'main-icon needs-fill' : 'main-icon'}>
              {location}
            </div>
          )}
          <div className='info'>
            <h5>{productName}</h5>
          </div>
        </header>
      </div>
    </Wrapper>
  );
};

export default MachineProduct;

import React, { useState } from 'react';

import { useAppContext } from '../../context/appContext';

import { Alert } from '../../components';

import Wrapper from '../../assets/wrappers/ErrorPage';

const SpoilageReport = () => {
  const { spoilage, adminReviewSpoilage, adminReviewed } = useAppContext();
  const isAdminReviewed = adminReviewed === 'true';

  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const itemsPerPage = 10;
  const numOfPages = Math.ceil(spoilage.length / itemsPerPage);

  // Calculate the data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSpoilage = spoilage.slice(indexOfFirstItem, indexOfLastItem);

  // Inline styles for table cells
  const thStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    backgroundColor: '#66b3ff',
    color: '#ffffff',
    textAlign: 'center',
  };

  const tdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
  };

  const buttonStyle = (index) => ({
    backgroundColor: hoveredRowIndex === index ? '#e60000' : '#ff6667',
    color: '#ffffff',
    textAlign: 'center',
    borderRadius: '5px',
    border: '1px solid #e60000',
    cursor: 'pointer',
    padding: '5px 10px',
  });

  const handleMouseEnter = (index) => setHoveredRowIndex(index);
  const handleMouseLeave = () => setHoveredRowIndex(null);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    const HH = String(date.getHours()).padStart(2, '0');
    const MM = String(date.getMinutes()).padStart(2, '0');

    return `${mm}/${dd}/${yyyy} ${HH}:${MM}`;
  };

  const handleButtonClick = (rowData) => {
    //console.log('Row data:', rowData);

    adminReviewSpoilage(rowData._id);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= numOfPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <Wrapper className='full-page'>
      <div>
        <Alert />
        {isAdminReviewed ? (
          <h2>Admin Spoilage Repot</h2>
        ) : (
          <h2>Spoilage Report</h2>
        )}
        {spoilage.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <p>No spoilage records found.</p>
          </div>
        ) : (
          <>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                backgroundColor: 'white',
              }}
            >
              <thead>
                <tr>
                  {!isAdminReviewed && <th style={thStyle}>Actions</th>}
                  <th style={thStyle}>Product Name</th>
                  <th style={thStyle}>Product Type</th>
                  <th style={thStyle}>Location</th>
                  <th style={thStyle}>Price</th>
                  <th style={thStyle}>Machine Name</th>
                  <th style={thStyle}>Quantity</th>
                  <th style={thStyle}>Note</th>
                  {isAdminReviewed && <th style={thStyle}>Admin Note</th>}
                  <th style={thStyle}>Reporter</th>
                  <th style={thStyle}>Date and Time</th>
                </tr>
              </thead>
              <tbody>
                {currentSpoilage.map((row, index) => (
                  <tr key={index}>
                    {!isAdminReviewed && (
                      <td style={tdStyle}>
                        {' '}
                        <button
                          style={buttonStyle(index)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleButtonClick(row)}
                        >
                          Admin Review
                        </button>
                      </td>
                    )}
                    <td style={tdStyle}>{row.productName}</td>
                    <td style={tdStyle}>{row.productType}</td>
                    <td style={tdStyle}>{row.location}</td>
                    <td style={tdStyle}>{row.price}</td>
                    <td style={tdStyle}>{row.machineName}</td>
                    <td style={tdStyle}>{row.quantity}</td>
                    <td style={tdStyle}>{row.note}</td>
                    {isAdminReviewed && (
                      <td style={tdStyle}>{row.adminReviewNote}</td>
                    )}
                    <td style={tdStyle}>{row.reporter}</td>
                    <td style={tdStyle}>{formatDate(row.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span style={{ margin: '0 10px' }}>
                Page {currentPage} of {numOfPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === numOfPages}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default SpoilageReport;

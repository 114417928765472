import styled from 'styled-components';

const Wrapper = styled.section`
  h2 {
    text-transform: none;
  }
  & > h5 {
    font-weight: 700;
  }
  .products {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
  @media (min-width: 992px) {
    .products {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  .products-small-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-6 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-7 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-8 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-9 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .products-small-10 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
  }
  .machine-product {
    height: 100%;
  }

  .scrollable-container {
    overflow-y: auto; /* Add vertical scrollbars when necessary */
    height: 100%; /* Set a maximum height if desired */
  }
  .full-container {
    max-height: 100%; /* Set a maximum height if desired */
  }
  .sticky-component {
    position: sticky;
    top: 110px;
  }
`;
export default Wrapper;

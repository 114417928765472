import React, { useReducer, useContext } from 'react';

import reducer from './reducer';
import axios from 'axios';

import {
  //Alerts
  CLEAR_ALERT,
  DISPLAY_ALERT,
  DISPLAY_CUSTOM_ALERT,
  CLEAR_CUSTOM_ALERT,
  GET_ALERTS_BEGIN,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_ERROR,
  ARCHIVE_ALERT_BEGIN,
  ARCHIVE_ALERT_SUCCESS,
  ARCHIVE_ALERT_ERROR,
  //User
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_JOB_BEGIN,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  GET_JOBS_BEGIN,
  GET_JOBS_SUCCESS,
  SET_EDIT_JOB,
  DELETE_JOB_BEGIN,
  EDIT_JOB_BEGIN,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  //Product
  CREATE_PRODUCT_BEGIN,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  SET_EDIT_PRODUCT,
  EDIT_PRODUCT_BEGIN,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  DELETE_PRODUCT_BEGIN,
  DOWNLOAD_PRODUCTS_BEGIN,
  DOWNLOAD_PRODUCTS_SUCCESS,
  DOWNLOAD_PRODUCTS_ERROR,
  //Machine
  CREATE_MACHINE_BEGIN,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_ERROR,
  GET_MACHINES_BEGIN,
  GET_MACHINES_SUCCESS,
  SET_EDIT_MACHINE,
  EDIT_MACHINE_BEGIN,
  EDIT_MACHINE_SUCCESS,
  EDIT_MACHINE_ERROR,
  DELETE_MACHINE_BEGIN,
  EDIT_MACHINE_ACTIVITY,
  SET_MACHINE_BEGIN,
  SET_MACHINE_SUCCESS,
  SET_MACHINE_ERROR,
  SET_ADD_MACHINE_PRODUCT,
  REMOVE_SELECTED_MACHINE,
  ADD_MACHINE_PRODUCT_BEGIN,
  ADD_MACHINE_PRODUCT_SUCCESS,
  ADD_MACHINE_PRODUCT_ERROR,
  DELETE_MACHINE_PRODUCT,
  TOGGLE_MACHINE_PRODUCT,
  TOGGLE_MACHINE_NOTE,
  TOGGLE_MACHINE_ADD_NOTE,
  SET_MACHINE_STATUS,
  EDIT_MACHINE_PRODUCT_BEGIN,
  EDIT_MACHINE_PRODUCT_SUCCESS,
  EDIT_MACHINE_PRODUCT_ERROR,
  EDIT_CURRENT_STOCK_BEGIN,
  EDIT_CURRENT_STOCK_SUCCESS,
  EDIT_CURRENT_STOCK_ERROR,
  EDIT_MACHINE_PRODUCT_LOCATION_BEGIN,
  EDIT_MACHINE_PRODUCT_LOCATION_SUCCESS,
  EDIT_MACHINE_PRODUCT_LOCATION_ERROR,
  FILL_SELECTED_MACHINE_BEGIN,
  FILL_SELECTED_MACHINE_SUCCESS,
  FILL_SELECTED_MACHINE_ERROR,
  UPDATE_MACHINE_STATUS,
  DRIVER_FILL_SELECTED_MACHINE_BEGIN,
  DRIVER_FILL_SELECTED_MACHINE_SUCCESS,
  DRIVER_FILL_SELECTED_MACHINE_ERROR,
  DRIVER_FILL_SELECTED_MACHINE_ABORT,
  DRIVER_REPLACE_ITEM_FLAG_BEGIN,
  DRIVER_REPLACE_ITEM_FLAG_SUCCESS,
  DRIVER_REPLACE_ITEM_FLAG_ERROR,
  UPDATE_GRID_VIEW,
  UPDATE_PRODUCT_INFO_VIEW,
  SHOW_HIDE_PRODUCT_INFO_VIEW,
  CLEAR_SELECTED_PRODUCT,
  TOGGLE_STOCKER_OTHER_AMOUNT,
  TOGGLE_DRIVER_OTHER_AMOUNT,
  TOGGLE_INDEPENDENT_PRICING_BEGIN,
  TOGGLE_INDEPENDENT_PRICING_SUCCESS,
  TOGGLE_INDEPENDENT_PRICING_ERROR,
  //User
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  SET_EDIT_USER,
  EDIT_USER_BEGIN,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER_BEGIN,
  TOGGLE_USER_ACTIONS,
  GET_ACTIONS_BEGIN,
  GET_ACTIONS_SUCCESS,
  SET_USER_BEGIN,
  SET_USER_SUCCESS,
  SET_USER_ERROR,
  TOGGLE_USER_STATUS_BEGIN,
  TOGGLE_USER_STATUS_SUCCESS,
  TOGGLE_USER_STATUS_ERROR,
  ADD_EXTENDED_REGISTRATION_DATE_BEGIN,
  ADD_EXTENDED_REGISTRATION_DATE_SUCCESS,
  ADD_EXTENDED_REGISTRATION_DATE_ERROR,
  //Note
  CREATE_NOTE_BEGIN,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERROR,
  GET_NOTES_BEGIN,
  GET_NOTES_SUCCESS,
  DELETE_NOTE_BEGIN,
  ARCHIVE_NOTE_BEGIN,
  //File Upload & Transactions
  TOGGLE_FILE_UPLOAD,
  FILE_UPLOAD_BEGIN,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERROR,
  PROCESS_TRANSACTIONS_BEGIN,
  PROCESS_TRANSACTIONS_SUCCESS,
  PROCESS_TRANSACTIONS_ERROR,
  GET_DAILY_TRANSACTIONS_BEGIN,
  GET_DAILY_TRANSACTIONS_SUCCESS,
  GET_DAILY_TRANSACTIONS_ERROR,
  REMOVE_TRANSACTION_FILE_BEGIN,
  REMOVE_TRANSACTION_FILE_SUCCESS,
  REMOVE_TRANSACTION_FILE_ERROR,
  //Stocked and Delivered
  GET_STOCKED_DELIVERED_BEGIN,
  GET_STOCKED_DELIVERED_SUCCESS,
  GET_STOCKED_DELIVERED_ERROR,
  //Error Report
  TOGGLE_ERROR_REPORT,
  GET_ERROR_REPORT_BEGIN,
  GET_ERROR_REPORT_SUCCESS,
  GET_ERROR_REPORT_ERROR,
  //Transaction Report
  TOGGLE_TRANSACTION_REPORT,
  GET_TRANSACTION_REPORT_BEGIN,
  GET_TRANSACTION_REPORT_SUCCESS,
  GET_TRANSACTION_REPORT_ERROR,
  //Monthly Transactions
  GET_MONTHLY_TRANSACTIONS_BEGIN,
  GET_MONTHLY_TRANSACTIONS_SUCCESS,
  GET_MONTHLY_TRANSACTIONS_ERROR,
  TOGGLE_MONTHY_TRANSACTIONS,
  GET_MONTHLY_TRANSACTIONS_REPORT_BEGIN,
  GET_MONTHLY_TRANSACTIONS_REPORT_SUCCESS,
  GET_MONTHLY_TRANSACTIONS_REPORT_ERROR,
  //Spoilage
  TOGGLE_SPOILAGE,
  GET_SPOILAGE_BEGIN,
  GET_SPOILAGE_SUCCESS,
  GET_SPOILAGE_ERROR,
  CREATE_SPOILAGE_BEGIN,
  CREATE_SPOILAGE_SUCCESS,
  CREATE_SPOILAGE_ERROR,
  ADMIN_REVIEW_SPOILAGE_BEGIN,
  ADMIN_REVIEW_SPOILAGE_SUCCESS,
  ADMIN_REVIEW_SPOILAGE_ERROR,
  //Message
  CREATE_MESSAGE_BEGIN,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
  //Show Message
  TOGGLE_SHOW_MESSAGE,
  //Get Messages
  GET_MESSAGES_BEGIN,
  GET_MESSAGES_SUCCESS,
  //Mark message as read
  MARK_MESSAGE_AS_READ_BEGIN,
  MARK_MESSAGE_AS_READ_SUCCESS,
  MARK_MESSAGE_AS_READ_ERROR,
  //Admin Override
  CREATE_ADMIN_OVERRIDE_BEGIN,
  CREATE_ADMIN_OVERRIDE_SUCCESS,
  CREATE_ADMIN_OVERRIDE_ERROR,
  UPDATE_ADMIN_OVERRIDE_BEGIN,
  UPDATE_ADMIN_OVERRIDE_SUCCESS,
  UPDATE_ADMIN_OVERRIDE_ERROR,
  GET_ADMIN_OVERRIDE_BEGIN,
  GET_ADMIN_OVERRIDE_SUCCESS,
  GET_ALLOWED_MACHINES_BEGIN,
  GET_ALLOWED_MACHINES_SUCCESS,
  GET_ALLOWED_MACHINES_ERROR,
  UPDATE_ALLOWED_MACHINES_BEGIN,
  UPDATE_ALLOWED_MACHINES_SUCCESS,
  UPDATE_ALLOWED_MACHINES_ERROR,
  GET_ACTIVE_MACHINES_BEGIN,
  GET_ACTIVE_MACHINES_SUCCESS,
  GET_ACTIVE_MACHINES_ERROR,
  //Stock Check
  RUN_STOCK_CHECK_BEGIN,
  RUN_STOCK_CHECK_SUCCESS,
  RUN_STOCK_CHECK_ERROR,
  //Stocker Replace Product
  STOCKER_REPLACE_PRODUCT_BEGIN,
  STOCKER_REPLACE_PRODUCT_SUCCESS,
  STOCKER_REPLACE_PRODUCT_ERROR,
  TOGGLE_STOCKER_REPLACE,
  STOCKER_SELECT_REPLACE_PRODUCT,
  //Driver Replace Product
  TOGGLE_DRIVER_REPLACE,
  DRIVER_REPLACE_PRODUCT_BEGIN,
  DRIVER_REPLACE_PRODUCT_SUCCESS,
  DRIVER_REPLACE_PRODUCT_ERROR,
  //Stocker Search Products
  STOCKER_SEARCH_PRODUCTS,
  GET_STOCKER_PRODUCTS_BEGIN,
  GET_STOCKER_PRODUCTS_SUCCESS,
  //Special
  UPDATE_PRODUCT_IDS_BEGIN,
  UPDATE_PRODUCT_IDS_SUCCESS,
  UPDATE_PRODUCT_IDS_ERROR,
  VERIFY_USER_ROLE_BEGIN,
  VERIFY_USER_ROLE_SUCCESS,
  VERIFY_USER_ROLE_ERROR,
  //Theme
  TOGGLE_THEME_BEGIN,
  TOGGLE_THEME_SUCCESS,
  TOGGLE_THEME_ERROR,
} from './actions';

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');
const userLocation = localStorage.getItem('location');

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  showSidebar: false,
  isEditing: false,
  alerts: [],
  // User
  editUserId: '',
  users: [],
  totalUsers: 0,
  user: user ? JSON.parse(user) : null,
  token: token,
  userLocation: userLocation || '',
  name: '',
  lastName: '',
  userTypeOptions: [
    'stocker',
    'driver',
    'stocker-driver',
    'admin',
    'super-admin',
  ],
  email: '',
  role: 'stocker',
  editJobId: '',
  position: '',
  company: '',
  jobLocation: userLocation || '',
  jobTypeOptions: ['full-time', 'part-time', 'remote', 'internship'],
  jobType: 'full-time',
  statusOptions: ['pending', 'interview', 'declined'],
  status: 'pending',
  jobs: [],
  totalJobs: 0,
  numOfPages: 1,
  page: 1,
  stats: {},
  monthlyApplications: [],
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sort: 'a-z',
  sortOptions: ['a-z', 'z-a', 'latest', 'oldest'],
  stockerSortOptions: ['a-z', 'z-a'],
  showActions: false,
  selectedUser: '',
  actions: [],
  totalActions: 0,
  selectedUserId: '',
  selectedUserName: '',
  selectedUserEmail: '',
  selectedLastName: '',
  selectedRole: '',
  selectedCreatedAt: '',
  selectedUserStatus: false,
  verifiedUserRole: '',
  // Products
  editProductId: '',
  productName: '',
  productType: 'single',
  productTypeOptions: [
    'single',
    'double',
    'fridge',
    'frozen',
    'drinks',
    'non-food',
  ],
  category: 'CANDY',
  categoryOptions: [
    'CANDY',
    'Chips LSS',
    'Chips Mini',
    'Chips XLG',
    'Cookies',
    'Drinks 20 oz',
    'Drinks Energy',
    'Drinks Isotonic',
    'Drinks Juice/Tea',
    'Drinks 12oz/Misc',
    'Drinks WATER',
    'Frozen',
    'Gum/Mints',
    'Ice Cream',
    'Healthy4U',
    'Pastry',
    'PEG Candy',
    'Shelf Stable Food',
    'Sundry',
  ],
  vending: 0,
  caseCost: 0,
  caseQty: 0,
  supplier: "Sam's Club",
  supplierOptions: ["Sam's Club", 'Costco', 'Vistar', 'Coke', 'Pepsi', 'Other'],
  products: [],
  totalProducts: 0,
  //Spoilage
  spoilage: [],
  totalSpoilage: 0,
  adminReviewed: false,
  // Machine
  isAdding: false,
  addingMachineId: '',
  editMachineId: '',
  machineName: '',
  deviceId: '',
  machineType: 'snack',
  machineTypeOptions: ['snack', 'drink', 'combo', 'freezer', 'coffee'],
  status: '',
  activity: false,
  activityOptions: ['all', 'active', 'inactive'],
  region: 'all',
  regionOptions: [
    'all',
    'Bonn - Blue',
    'Downtown - Green',
    'RAC - Pink',
    '3300 South - Yellow',
    'VA - White',
  ],
  country: 'USA',
  usState: 'Utah',
  city: '',
  zip: '',
  address: '',
  note: '',
  machines: [],
  totalMachines: 0,
  activeInactive: 'all',
  machineStatus: 'all',
  machineStatusOptions: [
    'all',
    'na',
    'vending',
    'needs stock',
    'stocked',
    'delivered',
  ],
  rows: 0,
  columns: 0,
  //SelectedMachine
  showMachineProduct: true,
  showMachineNote: false,
  showMachineAddNote: false,
  selectedId: '',
  selectedMachineName: '',
  selectedDeviceId: '',
  selectedMachineType: '',
  selectedStatus: '',
  selectedActivity: '',
  selectedRegion: '',
  selectedCountry: '',
  selectedUsState: '',
  selectedCity: '',
  selectedZip: '',
  selectedAddress: '',
  selectedNote: '',
  selectedTopSeller: '',
  selectedServicedOn: '',
  selectedServicedBy: '',
  selectedCreatedBy: '',
  selectedCreatedAt: '',
  selectedRows: [],
  selectedColumns: 0,
  selectedReplaceFlag: false,
  selectedReplaceMessage: '',
  selectedReplaceDate: '',
  selectedReplaceUser: '',
  gridView: true,
  productInfoView: false,
  //Note
  newNote: '',
  noteType: 'all',
  severity: 'low',
  severityTypeOptions: ['low', 'medium', 'high'],
  noteTypeOptions: ['machine', 'product', 'user', 'other'],
  notes: [],
  totalNotes: 0,
  //Stocker
  isStocker: false,
  stockerOtherAmount: 0,
  stockerShowOtherAmount: false,
  stockerShowReplace: false,
  stockerReplacedProductId: '',
  stockerReplacedProductName: '',
  stockerReplacedProductType: '',
  stockerReplacedProductPrice: 0,
  stockerReplacedProductMaxStock: 0,
  //Driver
  isDriver: false,
  driverOtherAmount: 0,
  driverShowOtherAmount: false,
  driverShowReplace: false,
  //FileUpload
  showFileUpload: false,
  fileUploaded: false,
  //Daily Transactions
  dailyReports: 0,
  dailyProcessedDate: '',
  dailyTransactions: 0,
  dailyTotal: 0,
  stocked: 0,
  delivered: 0,
  needsStock: 0,
  //Error Report
  emptyColumnSpin: 0,
  machineNotFound: 0,
  showErrorReport: false,
  errors: [],
  //Transactions Report
  showTransactionReport: false,
  tranactionCount: 0,
  transactions: [],
  //Monthly Transactions
  showMonthlyTransactions: false,
  showSpoilage: false,
  monthlyTransactions: [],
  //Messages
  recieverName: '',
  messageType: '',
  messageTypeOptions: ['machine', 'product', 'user', 'other', 'stickied'],
  message: '',
  showMessage: false,
  messages: [],
  totalMessages: 0,
  totalUnredMessages: 0,
  //Admin
  adminOverridePassword: '',
  allowedMachines: 0,
  activeMachines: 0,
  //Selected Product
  selectedProductId: '',
  selectedProductName: '',
  selectedProductType: '',
  selectedLocation: '',
  selectedProductPrice: '',
  selectedProductSales: '',
  selectedProductMaxStock: '',
  selectedProductCurrentStock: '',
  selectedProductStocked: '',
  selectedProductStockedDate: '',
  selectedProductStockerReplaced: false,
  selectedProductIndependentPricing: false,
  //Stocker Search Product
  stockerProductNameSearch: '',
  stockerProductTypeSearch: 'all',
  stockerProductSortSearch: 'latest',
  stockerProductCategorySearch: 'all',
  stockerProductPageSearch: 1,
  stockerProducts: [],
  totalStockerProducts: 0,
  numOfStockerPages: 1,
  //Theme
  themePreference: 'light-mode',
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Axios
  const authFetch = axios.create({
    baseURL: '/api/v1',
  });

  //request
  authFetch.interceptors.request.use(
    (config) => {
      config.headers.common['Authorization'] = `Bearer ${state.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  //response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error.response);
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    },
  );

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const displayCustomAlert = (message, displayTime) => {
    dispatch({
      type: DISPLAY_CUSTOM_ALERT,
      payload: {
        alert: message,
      },
    });
    clearCustomAlert(displayTime);
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 3000);
  };

  const clearCustomAlert = (displayTime) => {
    setTimeout(() => {
      dispatch({ type: CLEAR_CUSTOM_ALERT });
    }, displayTime);
  };

  const addUserToLocalStorage = ({ user, token, location }) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('location', location);
  };

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('location');
  };

  const setupUser = async ({ currentUser, endpoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      const { data } = await axios.post(
        `/api/v1/auth/${endpoint}`,
        currentUser,
      );
      const { user, token, location } = data;

      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token, location, alertText },
      });

      addUserToLocalStorage({ user, token, location });
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const toggleMachineProduct = () => {
    dispatch({ type: TOGGLE_MACHINE_PRODUCT });
  };

  const toggleMachineNote = () => {
    dispatch({ type: TOGGLE_MACHINE_NOTE });
  };

  const toggleMachineAddNote = () => {
    dispatch({ type: TOGGLE_MACHINE_ADD_NOTE });
  };

  const toggleUserActions = (userId) => {
    dispatch({
      type: TOGGLE_USER_ACTIONS,
      payload: { selectedUser: userId },
    });
  };

  const toggleFileUpload = () => {
    dispatch({ type: TOGGLE_FILE_UPLOAD });
  };

  const toggleMonthlyTransactions = () => {
    dispatch({ type: TOGGLE_MONTHY_TRANSACTIONS });
  };

  const toggleSpoilage = () => {
    dispatch({ type: TOGGLE_SPOILAGE });
  };

  const toggleStockerOtherAmount = () => {
    dispatch({ type: TOGGLE_STOCKER_OTHER_AMOUNT });
  };

  const toggleDriverOtherAmount = () => {
    dispatch({ type: TOGGLE_DRIVER_OTHER_AMOUNT });
  };

  const toggleShowMessage = () => {
    dispatch({ type: TOGGLE_SHOW_MESSAGE });
  };

  const toggleErrorReport = () => {
    dispatch({ type: TOGGLE_ERROR_REPORT });
  };

  const toggleTransactionReport = () => {
    dispatch({ type: TOGGLE_TRANSACTION_REPORT });
  };

  const toggleStockerReplace = () => {
    dispatch({ type: TOGGLE_STOCKER_REPLACE });
  };

  const toggleDriverReplace = () => {
    dispatch({ type: TOGGLE_DRIVER_REPLACE });
  };

  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };

  const addExtendedRegistrationDate = async (selectedUserId) => {
    dispatch({
      type: ADD_EXTENDED_REGISTRATION_DATE_BEGIN,
    });

    try {
      const { data } = await authFetch.patch('/users/extendedRegistration', {
        selectedUserId,
      });
      console.log(data);

      dispatch({
        type: ADD_EXTENDED_REGISTRATION_DATE_SUCCESS,
      });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: ADD_EXTENDED_REGISTRATION_DATE_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const updateUserStatus = async (selectedUserId, updatedStatus) => {
    dispatch({
      type: TOGGLE_USER_STATUS_BEGIN,
    });

    try {
      const { data } = await authFetch.patch('/users/status', {
        selectedUserId,
        updatedStatus,
      });

      const newStatus = data.updateUser.status;

      dispatch({
        type: TOGGLE_USER_STATUS_SUCCESS,
        payload: { newStatus },
      });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: TOGGLE_USER_STATUS_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const updateUser = async (currentUser) => {
    dispatch({
      type: UPDATE_USER_BEGIN,
    });
    try {
      const { data } = await authFetch.patch('/auth/updateUser', currentUser);

      const { user, location, token } = data;

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, location, token },
      });
      addUserToLocalStorage({ user, location, token });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const handleChange = ({ name, value }) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: { name, value },
    });
  };

  const stockerProductSearch = (
    productName,
    productType,
    productSort,
    categoryType,
  ) => {
    dispatch({
      type: STOCKER_SEARCH_PRODUCTS,
      payload: { productName, productType, productSort, categoryType },
    });
  };

  const clearValues = () => {
    dispatch({
      type: CLEAR_VALUES,
    });
  };

  const createJob = async () => {
    dispatch({
      type: CREATE_JOB_BEGIN,
    });
    try {
      const { position, company, jobLocation, jobType, status } = state;

      await authFetch.post('/jobs', {
        position,
        company,
        jobLocation,
        jobType,
        status,
      });

      dispatch({
        type: CREATE_JOB_SUCCESS,
      });
      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_JOB_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createProduct = async () => {
    dispatch({
      type: CREATE_PRODUCT_BEGIN,
    });
    try {
      const {
        productName,
        productType,
        productTypeOptions,
        category,
        categoryOptions,
        vending,
        caseCost,
        caseQty,
        supplier,
      } = state;

      await authFetch.post('/products', {
        productName,
        productType,
        productTypeOptions,
        category,
        categoryOptions,
        vending,
        caseCost,
        caseQty,
        supplier,
      });

      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
      });
      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_PRODUCT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createMachine = async () => {
    dispatch({
      type: CREATE_MACHINE_BEGIN,
    });
    try {
      const {
        machineName,
        deviceId,
        machineType,
        machineTypeOptions,
        region,
        country,
        usState,
        city,
        zip,
        address,
        note,
        rows,
        columns,
      } = state;

      await authFetch.post('/machines', {
        machineName,
        deviceId,
        machineType,
        machineTypeOptions,
        region,
        country,
        usState,
        city,
        zip,
        address,
        note,
        rows,
        columns,
      });

      dispatch({
        type: CREATE_MACHINE_SUCCESS,
      });
      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createUser = async () => {
    dispatch({
      type: CREATE_USER_BEGIN,
    });
    try {
      const { name, email, lastName, role } = state;

      await authFetch.post('/users', {
        name,
        email,
        lastName,
        role,
      });

      dispatch({
        type: CREATE_USER_SUCCESS,
      });
      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createNote = async () => {
    dispatch({
      type: CREATE_NOTE_BEGIN,
    });
    try {
      const { newNote, noteType, severity, selectedId } = state;

      await authFetch.post('/machineNotes', {
        newNote,
        noteType,
        severity,
        selectedId,
      });

      dispatch({
        type: CREATE_NOTE_SUCCESS,
      });
      dispatch({
        type: CLEAR_VALUES,
      });

      //get notes after add
      getNotes();
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_NOTE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createAdminOverrirde = async (adminOverridePassword) => {
    dispatch({
      type: CREATE_ADMIN_OVERRIDE_BEGIN,
    });
    try {
      await authFetch.post('/admin', { adminOverridePassword });

      dispatch({
        type: CREATE_ADMIN_OVERRIDE_SUCCESS,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ADMIN_OVERRIDE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const updateAdminOverride = async (adminOverridePassword) => {
    dispatch({
      type: UPDATE_ADMIN_OVERRIDE_BEGIN,
    });
    try {
      await authFetch.patch('/admin', { adminOverridePassword });

      dispatch({
        type: UPDATE_ADMIN_OVERRIDE_SUCCESS,
      });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_ADMIN_OVERRIDE_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const getAdminOverride = async () => {
    dispatch({ type: GET_ADMIN_OVERRIDE_BEGIN });
    try {
      const { data } = await authFetch.get(`/admin`);
      const { password } = data;

      dispatch({
        type: GET_ADMIN_OVERRIDE_SUCCESS,
        payload: {
          password,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const createMessage = async () => {
    dispatch({
      type: CREATE_MESSAGE_BEGIN,
    });
    try {
      const { recieverName, messageType, message } = state;

      await authFetch.post('/messages', {
        recieverName,
        messageType,
        message,
      });

      dispatch({
        type: CREATE_MESSAGE_SUCCESS,
      });
      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_MESSAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const getJobs = async () => {
    const { page, search, searchStatus, searchType, sort } = state;

    let url = `/jobs?page=${page}&status=${searchStatus}&jobType=${searchType}&sort=${sort}`;
    if (search) {
      url = url + `&search=${search}`;
    }
    dispatch({ type: GET_JOBS_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { jobs, totalJobs, numOfPages } = data;
      dispatch({
        type: GET_JOBS_SUCCESS,
        payload: {
          jobs,
          totalJobs,
          numOfPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getProducts = async () => {
    const { page, search, searchType, sort, category } = state;
    let url = `/products?page=${page}&productType=${searchType}&sort=${sort}&category=${category}`;
    if (search) {
      url = url + `&search=${search}`;
    }
    dispatch({ type: GET_PRODUCTS_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { products, totalProducts, numOfPages } = data;
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: {
          products,
          totalProducts,
          numOfPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getStockerProducts = async () => {
    const {
      stockerProductNameSearch,
      stockerProductTypeSearch,
      stockerProductSortSearch,
      stockerProductPageSearch,
      stockerProductCategorySearch,
    } = state;
    let url = `/products/stocker?page=${stockerProductPageSearch}&productType=${stockerProductTypeSearch}&sort=${stockerProductSortSearch}&category=${stockerProductCategorySearch}`;

    if (stockerProductNameSearch) {
      url = url + `&search=${stockerProductNameSearch}`;
    }

    dispatch({ type: GET_STOCKER_PRODUCTS_BEGIN });

    try {
      const { data } = await authFetch(url);
      const { stockerProducts, totalStockerProducts, numOfStockerPages } = data;

      dispatch({
        type: GET_STOCKER_PRODUCTS_SUCCESS,
        payload: {
          stockerProducts,
          totalStockerProducts,
          numOfStockerPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getMachines = async () => {
    const {
      page,
      search,
      searchStatus,
      searchType,
      sort,
      activeInactive,
      machineStatus,
      region,
    } = state;

    let url = `/machines?page=${page}&status=${searchStatus}&machineType=${searchType}&sort=${sort}&activeInactive=${activeInactive}&machineStatus=${machineStatus}&region=${region}`;

    if (search) {
      url = url + `&search=${search}`;
    }
    dispatch({ type: GET_MACHINES_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { machines, totalMachines, numOfPages } = data;
      dispatch({
        type: GET_MACHINES_SUCCESS,
        payload: {
          machines,
          totalMachines,
          numOfPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getUsers = async () => {
    const { page, search, searchStatus, searchType, sort } = state;

    let url = `/users?page=${page}&status=${searchStatus}&userType=${searchType}&sort=${sort}`;
    if (search) {
      url = url + `&search=${search}`;
    }

    dispatch({ type: GET_USERS_BEGIN });
    try {
      const { data } = await authFetch(url);
      const { users, totalUsers, numOfPages } = data;
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: {
          users,
          totalUsers,
          numOfPages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getNotes = async () => {
    dispatch({ type: GET_NOTES_BEGIN });
    try {
      const { data } = await authFetch.get(
        `/machineNotes/machine/${state.selectedId}`,
      );
      const { notes, totalNotes } = data;
      dispatch({
        type: GET_NOTES_SUCCESS,
        payload: {
          notes,
          totalNotes,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getNotesWithId = async (machineId) => {
    dispatch({ type: GET_NOTES_BEGIN });
    try {
      const { data } = await authFetch.get(
        `/machineNotes/machine/${machineId}`,
      );
      const { notes, totalNotes } = data;
      dispatch({
        type: GET_NOTES_SUCCESS,
        payload: {
          notes,
          totalNotes,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const setEditJob = (id) => {
    dispatch({
      type: SET_EDIT_JOB,
      payload: { id },
    });
  };

  const getMessages = async (id) => {
    dispatch({ type: GET_MESSAGES_BEGIN });
    try {
      const { data } = await authFetch.get(`/messages/${id}`);
      const { messages, totalMessages, totalUnredMessages } = data;
      dispatch({
        type: GET_MESSAGES_SUCCESS,
        payload: {
          messages,
          totalMessages,
          totalUnredMessages,
        },
      });
    } catch (error) {
      logoutUser();
    }
    clearAlert();
  };

  const getActions = async (userId) => {
    dispatch({ type: GET_ACTIONS_BEGIN });
    try {
      const { data } = await authFetch.get(`/actions/${userId}`);
      const { actions, totalActions } = data;
      dispatch({
        type: GET_ACTIONS_SUCCESS,
        payload: {
          actions,
          totalActions,
        },
      });
    } catch (erro) {
      logoutUser();
    }
    clearAlert();
  };

  const setEditProduct = (id) => {
    dispatch({
      type: SET_EDIT_PRODUCT,
      payload: { id },
    });
  };

  const setEditMachine = (id) => {
    dispatch({
      type: SET_EDIT_MACHINE,
      payload: { id },
    });
  };

  const setEditUser = (id) => {
    dispatch({
      type: SET_EDIT_USER,
      payload: { id },
    });
  };

  const editJob = async () => {
    dispatch({
      type: EDIT_JOB_BEGIN,
    });
    try {
      const { position, company, jobLocation, jobType, status } = state;

      await authFetch.patch(`/jobs/${state.editJobId}`, {
        company,
        position,
        jobLocation,
        jobType,
        status,
      });

      dispatch({
        type: EDIT_JOB_SUCCESS,
      });

      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_JOB_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const editProduct = async () => {
    dispatch({
      type: EDIT_PRODUCT_BEGIN,
    });
    try {
      const {
        productName,
        productType,
        category,
        vending,
        caseCost,
        caseQty,
        supplier,
      } = state;

      await authFetch.patch(`/products/${state.editProductId}`, {
        productName,
        productType,
        category,
        vending,
        caseCost,
        caseQty,
        supplier,
      });

      dispatch({
        type: EDIT_PRODUCT_SUCCESS,
      });

      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_PRODUCT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const editMachine = async () => {
    dispatch({
      type: EDIT_MACHINE_BEGIN,
    });
    try {
      const {
        machineName,
        deviceId,
        machineType,
        region,
        country,
        usState,
        city,
        zip,
        address,
        note,
        rows,
        columns,
      } = state;

      await authFetch.patch(`/machines/${state.editMachineId}`, {
        machineName,
        deviceId,
        machineType,
        region,
        country,
        usState,
        city,
        zip,
        address,
        note,
        rows,
        columns,
      });

      dispatch({
        type: EDIT_MACHINE_SUCCESS,
      });

      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const editUser = async () => {
    dispatch({
      type: EDIT_USER_BEGIN,
    });
    try {
      const { name, lastName, email, role } = state;

      await authFetch.patch(`/users/${state.editUserId}`, {
        name,
        lastName,
        email,
        role,
      });

      dispatch({
        type: EDIT_USER_SUCCESS,
      });

      dispatch({
        type: CLEAR_VALUES,
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: EDIT_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const deleteJob = async (jobId) => {
    dispatch({
      type: DELETE_JOB_BEGIN,
    });
    try {
      await authFetch.delete(`/jobs/${jobId}`);
      getJobs();
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const deleteProduct = async (productId) => {
    dispatch({
      type: DELETE_PRODUCT_BEGIN,
    });
    try {
      await authFetch.delete(`/products/${productId}`);
      getProducts();
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const deleteMachine = async (machineId) => {
    let authorize = prompt(
      'Are you sure you want to delete this Machine? This action cannot be undone.',
      'ADMIN PASSWORD',
    );

    try {
      const { data } = await authFetch.get(`/admin`);
      const { password } = data;

      if (authorize === password) {
        dispatch({
          type: DELETE_MACHINE_BEGIN,
        });

        await authFetch.delete(`/machines/${machineId}`);
        getMachines();
      }
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const deleteUser = async (userId) => {
    let authorize = prompt(
      'Are you sure you want to delete this User? This action cannot be undone.',
      'ADMIN PASSWORD',
    );

    try {
      const { data } = await authFetch.get(`/admin`);
      const { password } = data;

      if (authorize === password) {
        dispatch({
          type: DELETE_USER_BEGIN,
        });

        await authFetch.delete(`/users/${userId}`);
        getUsers();
      }
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const deleteNote = async (noteId) => {
    dispatch({
      type: DELETE_NOTE_BEGIN,
    });
    try {
      await authFetch.delete(`/machineNotes/${noteId}`);
      getNotes();
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const archiveNote = async (noteId) => {
    dispatch({
      type: ARCHIVE_NOTE_BEGIN,
    });
    try {
      await authFetch.patch(`/machineNotes/archive/${noteId}`);
      getNotes();
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const editActivity = async (machineId, activity) => {
    dispatch({
      type: EDIT_MACHINE_ACTIVITY,
    });
    try {
      await authFetch.patch(`/machines/${machineId}/${activity}`);
      getMachines();
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const setMachine = async (machineId) => {
    dispatch({
      type: SET_MACHINE_BEGIN,
    });
    try {
      const { data } = await authFetch.get(`/machines/${machineId}`);
      const {
        _id,
        machineName,
        deviceId,
        machineType,
        region,
        activity,
        status,
        country,
        usState,
        city,
        zip,
        address,
        note,
        createdBy,
        createdAt,
        row,
        columns,
        lastVisitDate,
        servicedByName,
      } = data;

      dispatch({
        type: SET_MACHINE_SUCCESS,
        payload: {
          _id,
          machineName,
          deviceId,
          machineType,
          region,
          activity,
          status,
          country,
          usState,
          city,
          zip,
          address,
          note,
          createdAt,
          createdBy,
          row,
          columns,
          lastVisitDate,
          servicedByName,
        },
      });
      getNotesWithId(machineId);
    } catch (error) {
      dispatch({
        type: SET_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const setMachineProduct = async (id) => {
    dispatch({
      type: SET_ADD_MACHINE_PRODUCT,
      payload: { id },
    });
  };

  const setUser = async (userId) => {
    dispatch({
      type: SET_USER_BEGIN,
    });
    try {
      const { data } = await authFetch.get(`/users/${userId}`);
      const { _id, name, email, lastName, role, status, createdAt } = data;

      console.log(`Status: ${status}`);

      dispatch({
        type: SET_USER_SUCCESS,
        payload: {
          id: _id,
          userName: name,
          email: email,
          lastName: lastName,
          role: role,
          createdAt: createdAt,
          status: status,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const removeSelectedMachine = async (id) => {
    dispatch({
      type: REMOVE_SELECTED_MACHINE,
    });
  };

  const addMachineProduct = async (productId) => {
    dispatch({ type: ADD_MACHINE_PRODUCT_BEGIN });

    let location = prompt(
      'Please enter product location in machine (i.e. A4)',
      '',
    );

    if (location && location.length === 2) {
      let maxStock = prompt('Please enter Max Stock for product (i.e. 8', '');

      if (maxStock && !isNaN(maxStock)) {
        try {
          await authFetch.put(
            `/machines/${state.selectedId}/${productId}/${location}/${maxStock}`,
          );

          dispatch({
            type: ADD_MACHINE_PRODUCT_SUCCESS,
          });
        } catch (error) {
          dispatch({
            type: ADD_MACHINE_PRODUCT_ERROR,
            payload: { msg: error.response.data.msg },
          });
        }
      } else {
        alert('Max Stock must be a number (i.e. 8)');
      }
    } else {
      alert('Location must be exactly 2 character long (i.e. A4)');
    }
    clearAlert();
  };

  const deleteMachineProduct = async (machineId, rowId) => {
    let authorize = prompt(
      'Are you sure you want to delete this Product? This action cannot be undone.',
      'ADMIN PASSWORD',
    );

    try {
      const { data } = await authFetch.get(`/admin`);
      const { password } = data;

      if (authorize === password) {
        dispatch({
          type: DELETE_MACHINE_PRODUCT,
        });
        await authFetch.delete(`/machines/${machineId}/${rowId}`);
        setMachine(machineId);
      }
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const editMachineProduct = async (
    machineId,
    rowId,
    updatedLocation,
    updatedPrice,
    updatedMaxStock,
  ) => {
    dispatch({
      type: EDIT_MACHINE_PRODUCT_BEGIN,
    });

    try {
      await authFetch.put(
        `/machines/${machineId}/${rowId}/${updatedLocation}/${updatedPrice}/${updatedMaxStock}`,
      );

      dispatch({
        type: EDIT_MACHINE_PRODUCT_SUCCESS,
      });

      setMachine(machineId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: EDIT_MACHINE_PRODUCT_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const editCurrentStock = async (machineId, rowId, updatedCurrentStock) => {
    dispatch({
      type: EDIT_CURRENT_STOCK_BEGIN,
    });

    try {
      await authFetch.put(
        `/machines/${machineId}/${rowId}/${updatedCurrentStock}`,
      );

      dispatch({
        type: EDIT_CURRENT_STOCK_SUCCESS,
      });

      setMachine(machineId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: EDIT_CURRENT_STOCK_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const editMachineProductLocation = async (
    machineId,
    rowId,
    updatedRow,
    updatedColumn,
  ) => {
    dispatch({
      type: EDIT_MACHINE_PRODUCT_LOCATION_BEGIN,
    });

    try {
      await authFetch.put(
        `/machines/update/${machineId}/${rowId}/${updatedRow}/${updatedColumn}`,
      );

      dispatch({
        type: EDIT_MACHINE_PRODUCT_LOCATION_SUCCESS,
      });

      setMachine(machineId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: EDIT_MACHINE_PRODUCT_LOCATION_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const setMachineStatusStock = async () => {
    dispatch({
      type: SET_MACHINE_STATUS,
      payload: {
        machineStatus: 'needs stock',
        isStocker: true,
        isDriver: false,
      },
    });
  };

  const setMachineStatusDriver = async () => {
    dispatch({
      type: SET_MACHINE_STATUS,
      payload: { machineStatus: 'stocked', isDriver: true },
    });
  };

  const setMachineStatusAll = async () => {
    dispatch({
      type: SET_MACHINE_STATUS,
      payload: { machineStatus: 'all', isStocker: false, isDriver: false },
    });
  };

  const updateMachineStatus = async (selectedId, status) => {
    dispatch({
      type: UPDATE_MACHINE_STATUS,
    });

    try {
      await authFetch.patch(`/machines/status/${selectedId}/${status}`);
      getMachines();
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
  };

  const updateGridView = async () => {
    dispatch({
      type: UPDATE_GRID_VIEW,
    });
  };

  const showHideProductInfoView = async () => {
    dispatch({
      type: SHOW_HIDE_PRODUCT_INFO_VIEW,
    });
  };

  const clearSelectedProduct = async () => {
    dispatch({
      type: CLEAR_SELECTED_PRODUCT,
    });
  };

  const updateProductInfoView = async (
    _id,
    productName,
    productType,
    location,
    price,
    sales,
    maxStock,
    currentStock,
    stocked,
    stockedDate,
    stockerReplaced,
    stockerReplacedProductName,
    stockerReplacedProductType,
    stockerReplacedProductPrice,
    stockerReplacedProductMaxStock,
    replaceFlag,
    independentPricing,
  ) => {
    dispatch({
      type: UPDATE_PRODUCT_INFO_VIEW,
      payload: {
        id: _id,
        productName: productName,
        productType: productType,
        location: location,
        price: price,
        sales: sales,
        maxStock: maxStock,
        currentStock: currentStock,
        stocked: stocked,
        stockedDate: stockedDate,
        stockerReplaced: stockerReplaced,
        stockerReplacedProductName: stockerReplacedProductName,
        stockerReplacedProductType: stockerReplacedProductType,
        stockerReplacedProductPrice: stockerReplacedProductPrice,
        stockerReplacedProductMaxStock: stockerReplacedProductMaxStock,
        replaceFlag: replaceFlag,
        independentPricing: independentPricing,
      },
    });
  };

  const fillSelectedMachine = async (
    stockAmount,
    location,
    rowId,
    machineId,
    productName,
    machineName,
  ) => {
    dispatch({
      type: FILL_SELECTED_MACHINE_BEGIN,
    });

    try {
      await authFetch.patch('/machines', {
        machineId,
        location,
        rowId,
        stockAmount,
        productName,
        machineName,
      });

      dispatch({
        type: FILL_SELECTED_MACHINE_SUCCESS,
      });

      setMachine(machineId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: FILL_SELECTED_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const driverFillSelectedMachine = async (
    actualAmount,
    location,
    rowId,
    machineId,
    productName,
    machineName,
  ) => {
    dispatch({
      type: DRIVER_FILL_SELECTED_MACHINE_BEGIN,
    });

    try {
      await authFetch.patch('/machines/driver', {
        machineId,
        location,
        rowId,
        actualAmount,
        productName,
        machineName,
      });

      dispatch({
        type: DRIVER_FILL_SELECTED_MACHINE_SUCCESS,
      });

      setMachine(machineId);

      clearSelectedProduct();
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: DRIVER_FILL_SELECTED_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const otherFillAmount = async (
    stockAmount,
    location,
    rowId,
    machineId,
    productName,
    machineName,
  ) => {
    dispatch({
      type: FILL_SELECTED_MACHINE_BEGIN,
    });

    //let stockAmount = prompt("Please enter other amount. MUST BE A NUMBER.",);

    try {
      await authFetch.patch('/machines', {
        machineId,
        location,
        rowId,
        stockAmount,
        productName,
        machineName,
      });

      toggleStockerOtherAmount();

      dispatch({
        type: FILL_SELECTED_MACHINE_SUCCESS,
      });

      setMachine(machineId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: FILL_SELECTED_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const driverOtherFillAmount = async (
    actualAmount,
    location,
    rowId,
    machineId,
    productName,
    machineName,
    maxStock,
  ) => {
    dispatch({
      type: DRIVER_FILL_SELECTED_MACHINE_BEGIN,
    });

    const modifiedStock = maxStock + 3;
    if (modifiedStock < actualAmount) {
      let userResponse = window.confirm(
        'You have entered in an actual amount greater than the max stock (+3). Is this value correct? ',
      );
      if (!userResponse) {
        dispatch({
          type: DRIVER_FILL_SELECTED_MACHINE_ABORT,
        });
        return;
      }
    }

    try {
      await authFetch.patch('/machines/driver', {
        machineId,
        location,
        rowId,
        actualAmount,
        productName,
        machineName,
      });

      dispatch({
        type: DRIVER_FILL_SELECTED_MACHINE_SUCCESS,
      });

      toggleDriverOtherAmount();

      setMachine(machineId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: DRIVER_FILL_SELECTED_MACHINE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
  };

  const fileUpload = async (formData) => {
    dispatch({
      type: FILE_UPLOAD_BEGIN,
    });

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      //await axios.post(`/api/v1/transactions/uploads`, formData, config)
      await authFetch.post(`/transactions`, formData, config);

      dispatch({
        type: FILE_UPLOAD_SUCCESS,
      });
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: FILE_UPLOAD_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const processTransactions = async () => {
    dispatch({
      type: PROCESS_TRANSACTIONS_BEGIN,
    });

    try {
      await authFetch.post('/transactions/process');
      dispatch({
        type: PROCESS_TRANSACTIONS_SUCCESS,
      });
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: PROCESS_TRANSACTIONS_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const removeTransactionFile = async () => {
    dispatch({
      type: REMOVE_TRANSACTION_FILE_BEGIN,
    });

    try {
      await authFetch.delete('/transactions/remove');

      dispatch({
        type: REMOVE_TRANSACTION_FILE_SUCCESS,
      });
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: REMOVE_TRANSACTION_FILE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const showStats = async () => {
    dispatch({
      type: SHOW_STATS_BEGIN,
    });
    try {
      const { data } = await authFetch('/jobs/stats');
      dispatch({
        type: SHOW_STATS_SUCCESS,
        payload: {
          stats: data.defaultStats,
          monthlyApplications: data.monthlyApplications,
        },
      });
    } catch (error) {
      console.log(error.response);
      logoutUser();
    }
    clearAlert();
  };

  const clearFilters = () => {
    dispatch({
      type: CLEAR_FILTERS,
    });
  };

  const changePage = (page) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: { page },
    });
  };

  const getDailyTransactions = async () => {
    dispatch({ type: GET_DAILY_TRANSACTIONS_BEGIN });
    try {
      const { data } = await authFetch.get(`/dailyTransactions`);

      const { reports, processedDate, transactions, total } = data;
      dispatch({
        type: GET_DAILY_TRANSACTIONS_SUCCESS,
        payload: {
          reports,
          processedDate,
          transactions,
          total,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_DAILY_TRANSACTIONS_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const getStockedAndDelivered = async () => {
    dispatch({ type: GET_STOCKED_DELIVERED_BEGIN });
    try {
      const { data } = await authFetch.get(`/machines/counts`);

      const { stocked, delivered, needsStock } = data;

      dispatch({
        type: GET_STOCKED_DELIVERED_SUCCESS,
        payload: {
          stocked,
          delivered,
          needsStock,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_STOCKED_DELIVERED_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const getErrorReport = async () => {
    dispatch({ type: GET_ERROR_REPORT_BEGIN });
    try {
      const { data } = await authFetch.get(`/transactions/errors`);

      const { columnSpin, notFound, errArr } = data;
      dispatch({
        type: GET_ERROR_REPORT_SUCCESS,
        payload: {
          columnSpin,
          notFound,
          errArr,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_ERROR_REPORT_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const getTransactionReport = async () => {
    dispatch({ type: GET_TRANSACTION_REPORT_BEGIN });
    try {
      const { data } = await authFetch.get(`/transactions/`);

      const { transactionCount, transactionsArr } = data;
      dispatch({
        type: GET_TRANSACTION_REPORT_SUCCESS,
        payload: {
          transactionCount,
          transactionsArr,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_TRANSACTION_REPORT_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const getAllMonthlyTransactions = async () => {
    dispatch({ type: GET_MONTHLY_TRANSACTIONS_BEGIN });
    try {
      const { data } = await authFetch.get(`/transactions/monthly`);

      const { transactions } = data;
      dispatch({
        type: GET_MONTHLY_TRANSACTIONS_SUCCESS,
        payload: {
          transactions: transactions,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_MONTHLY_TRANSACTIONS_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const markMessageAsRead = async (id, userId) => {
    dispatch({ type: MARK_MESSAGE_AS_READ_BEGIN });
    try {
      await authFetch.put(`/messages/${id}`);

      dispatch({
        type: MARK_MESSAGE_AS_READ_SUCCESS,
      });

      getMessages(userId);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: MARK_MESSAGE_AS_READ_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const runStockCheck = async () => {
    dispatch({ type: RUN_STOCK_CHECK_BEGIN });
    try {
      const { data } = await authFetch.get(`/machines/stockCheck/`);

      dispatch({
        type: RUN_STOCK_CHECK_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: RUN_STOCK_CHECK_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const stockerReplaceProduct = async (
    machineId,
    machineName,
    productId,
    productName,
    productType,
    sales,
    location,
    price,
    maxStock,
  ) => {
    let newMaxStock = prompt(
      'Please enter NEW MAX STOCK amount. (Must be a number)',
    );

    dispatch({
      type: STOCKER_REPLACE_PRODUCT_BEGIN,
    });

    try {
      const {
        stockerReplacedProductId,
        stockerReplacedProductName,
        stockerReplacedProductType,
        stockerReplacedProductPrice,
      } = state;

      await authFetch.post('/archivedData/', {
        machineId,
        machineName,
        productId,
        productName,
        productType,
        sales,
        location,
        price,
        maxStock,
        stockerReplacedProductId,
        stockerReplacedProductName,
        stockerReplacedProductType,
        stockerReplacedProductPrice,
        newMaxStock,
      });

      dispatch({
        type: STOCKER_REPLACE_PRODUCT_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: STOCKER_REPLACE_PRODUCT_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const stockerSelectReplaceProduct = async (
    productId,
    productName,
    productType,
    productPrice,
  ) => {
    dispatch({
      type: STOCKER_SELECT_REPLACE_PRODUCT,
      payload: {
        productId,
        productName,
        productType,
        productPrice,
      },
    });
  };

  const driverReplaceProduct = async (
    machineId,
    machineName,
    location,
    productId,
    productName,
    productType,
    productPrice,
    productMaxStock,
  ) => {
    dispatch({
      type: DRIVER_REPLACE_PRODUCT_BEGIN,
    });

    try {
      await authFetch.patch('/machines/replace', {
        machineId,
        location,
        productId,
        productName,
        productType,
        productPrice,
        productMaxStock,
      });

      dispatch({
        type: DRIVER_REPLACE_PRODUCT_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: DRIVER_REPLACE_PRODUCT_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const getMonthlyTransactionsReport = async (
    reportMonth,
    reportYear,
    reportSearchType,
  ) => {
    console.log(
      `Month: ${reportMonth}, Year: ${reportYear}, Search Type: ${reportSearchType}`,
    );
    dispatch({ type: GET_MONTHLY_TRANSACTIONS_REPORT_BEGIN });
    try {
      const response = await authFetch.get('/transactions/reports', {
        params: {
          reportMonth,
          reportYear,
          reportSearchType,
        },
        responseType: 'blob', // Set the responseType to 'blob'
      });

      const blob = await response.data;

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${reportSearchType}.csv`;

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger a click event to download the file
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);

      dispatch({
        type: GET_MONTHLY_TRANSACTIONS_REPORT_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_MONTHLY_TRANSACTIONS_REPORT_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  //Spoilage
  const getSpoilage = async (
    reportMonth,
    reportYear,
    adminReviewed,
    machineName,
  ) => {
    // console.log(
    //   `getSpoilage: ${reportMonth}, ${reportYear}, ${adminReviewed} ${machineName}`,
    // );

    dispatch({ type: GET_SPOILAGE_BEGIN });
    try {
      const { data } = await authFetch.get('/spoilage', {
        params: {
          reportMonth,
          reportYear,
          adminReviewed,
          machineName,
        },
      });

      console.log(data);

      dispatch({
        type: GET_SPOILAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_SPOILAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const createSpoilage = async (
    productId,
    productName,
    productType,
    location,
    price,
    machineId,
    machineName,
    currentStock,
  ) => {
    // console.log(
    //   `createSpoilage: ${productId}, ${productName}, ${productType}, ${location}, ${price}, ${machineId}, ${machineName},`,
    // );

    let quantity = prompt(
      'How many products were spoiled? (Please enter a number i.e 1, 2, 3...).',
      '0',
    );

    quantity = Number(quantity) || 0;
    let updatedStock = currentStock - quantity;
    if (updatedStock < 0) {
      updatedStock = 0;
    }

    let note = prompt(
      'Please enter a short note explaining the spoilage.',
      'No explanation provided.',
    );

    try {
      dispatch({
        type: CREATE_SPOILAGE_BEGIN,
      });

      await authFetch.post('/spoilage', {
        productId,
        productName,
        productType,
        location,
        price,
        machineId,
        machineName,
        quantity,
        updatedStock,
        note,
      });

      dispatch({
        type: CREATE_SPOILAGE_SUCCESS,
      });

      alert('Spoilage report has been created reload machine to view updates.');
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: CREATE_SPOILAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const adminReviewSpoilage = async (id) => {
    console.log('adminReviewSpoilage');

    let adminReviewNote = prompt(
      'Please enter a short note for Admin Review.',
      'No explanation provided.',
    );

    try {
      dispatch({
        type: ADMIN_REVIEW_SPOILAGE_BEGIN,
      });

      await authFetch.post(`/spoilage/${id}`, { adminReviewNote });

      dispatch({
        type: ADMIN_REVIEW_SPOILAGE_SUCCESS,
      });

      //Call get spoilage to rerender SpoilageRport
      getSpoilage();
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: ADMIN_REVIEW_SPOILAGE_ERROR,
        payload: { msg: error.response.data.msg },
      });
      logoutUser();
    }
    clearAlert();
  };

  const updateProductIds = async (machineId) => {
    try {
      dispatch({
        type: UPDATE_PRODUCT_IDS_BEGIN,
      });

      await authFetch.post('/machines/updateAllProductsId', { machineId });

      dispatch({
        type: UPDATE_PRODUCT_IDS_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: UPDATE_PRODUCT_IDS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const verifyUserRole = async () => {
    try {
      dispatch({
        type: VERIFY_USER_ROLE_BEGIN,
      });

      const { data } = await authFetch.post('/users/verify');

      dispatch({
        type: VERIFY_USER_ROLE_SUCCESS,
        payload: {
          role: data,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: VERIFY_USER_ROLE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const getAllowedMachines = async () => {
    try {
      dispatch({
        type: GET_ALLOWED_MACHINES_BEGIN,
      });

      const { data } = await authFetch.get('/admin/allowed');

      dispatch({
        type: GET_ALLOWED_MACHINES_SUCCESS,
        payload: {
          allowedMachines: data.allowedMachines,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_ALLOWED_MACHINES_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const updateAllowedMachines = async (allowedMachines) => {
    try {
      dispatch({
        type: UPDATE_ALLOWED_MACHINES_BEGIN,
      });

      await authFetch.post('/admin/allowed', { allowedMachines });

      dispatch({
        type: UPDATE_ALLOWED_MACHINES_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: UPDATE_ALLOWED_MACHINES_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const getActiveMachines = async () => {
    try {
      dispatch({
        type: GET_ACTIVE_MACHINES_BEGIN,
      });

      const { data } = await authFetch.get('/admin/active');

      dispatch({
        type: GET_ACTIVE_MACHINES_SUCCESS,
        payload: {
          activeMachines: data.activeMachines,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_ACTIVE_MACHINES_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const replaceItemFlag = async (selectedMachineId, selectedProductId) => {
    let message = prompt(
      'Please enter a short note explaining the replace issue.',
    );

    try {
      if (message) {
        dispatch({
          type: DRIVER_REPLACE_ITEM_FLAG_BEGIN,
        });

        const driverIssue = message ? message : 'Driver did not add issue';

        const { data } = await authFetch.patch(
          `/machines/replaceFlag/${selectedMachineId}/${selectedProductId}`,
          { driverIssue },
        );

        dispatch({
          type: DRIVER_REPLACE_ITEM_FLAG_SUCCESS,
          payload: {
            replaceFlag: data.flag,
            replaceMessage: data.replaceMessage,
            replaceDate: data.replaceDate,
            replaceUser: data.replaceUser,
          },
        });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: DRIVER_REPLACE_ITEM_FLAG_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const getAlerts = async () => {
    try {
      dispatch({
        type: GET_ALERTS_BEGIN,
      });

      const { data } = await authFetch.get('/alerts');

      //console.log(data);

      dispatch({
        type: GET_ALERTS_SUCCESS,
        payload: {
          alerts: data,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_ALERTS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const archiveAlert = async (alertId) => {
    try {
      dispatch({
        type: ARCHIVE_ALERT_BEGIN,
      });

      const { data } = await authFetch.patch(`/alerts/${alertId}`);

      console.log(data);

      dispatch({
        type: ARCHIVE_ALERT_SUCCESS,
        payload: {
          alerts: data,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: ARCHIVE_ALERT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const downloadProducts = async () => {
    try {
      dispatch({
        type: DOWNLOAD_PRODUCTS_BEGIN,
      });

      const response = await authFetch.get('/products/download');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after download

      dispatch({
        type: DOWNLOAD_PRODUCTS_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: DOWNLOAD_PRODUCTS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const toggleIndependentPricing = async (
    machineId,
    productId,
    independentPricing,
  ) => {
    try {
      dispatch({
        type: TOGGLE_INDEPENDENT_PRICING_BEGIN,
      });

      const { data } = await authFetch.patch('/machines/independent/pricing', {
        machineId,
        productId,
        independentPricing,
      });

      const row = data.row;

      dispatch({
        type: TOGGLE_INDEPENDENT_PRICING_SUCCESS,
        payload: { independentPricing: row.independentPricing },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: TOGGLE_INDEPENDENT_PRICING_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const toggleThemePreference = async (userId) => {
    try {
      dispatch({
        type: TOGGLE_THEME_BEGIN,
      });

      const { data } = await authFetch.post('/users/theme/', { userId });

      dispatch({
        type: TOGGLE_THEME_SUCCESS,
        payload: { themePreference: data.themePreference },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: TOGGLE_THEME_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        displayAlert,
        setupUser,
        displayCustomAlert,
        getAlerts,
        archiveAlert,
        toggleSidebar,
        logoutUser,
        updateUser,
        handleChange,
        clearValues,
        createJob,
        getJobs,
        setEditJob,
        deleteJob,
        editJob,
        showStats,
        clearFilters,
        changePage,
        //Product
        createProduct,
        getProducts,
        setEditProduct,
        editProduct,
        deleteProduct,
        downloadProducts,
        //Machine
        createMachine,
        getMachines,
        setEditMachine,
        editMachine,
        deleteMachine,
        editActivity,
        setMachine,
        setMachineProduct,
        removeSelectedMachine,
        addMachineProduct,
        deleteMachineProduct,
        toggleMachineProduct,
        setMachineStatusStock,
        setMachineStatusAll,
        editMachineProduct,
        editCurrentStock,
        fillSelectedMachine,
        otherFillAmount,
        updateMachineStatus,
        setMachineStatusDriver,
        driverFillSelectedMachine,
        driverOtherFillAmount,
        editMachineProductLocation,
        updateGridView,
        updateProductInfoView,
        showHideProductInfoView,
        clearSelectedProduct,
        toggleStockerOtherAmount,
        toggleDriverOtherAmount,
        replaceItemFlag,
        toggleIndependentPricing,
        //User
        updateUserStatus,
        createUser,
        getUsers,
        setEditUser,
        editUser,
        deleteUser,
        toggleUserActions,
        getActions,
        setUser,
        addExtendedRegistrationDate,
        verifyUserRole,
        //Note
        toggleMachineNote,
        toggleMachineAddNote,
        createNote,
        getNotes,
        deleteNote,
        archiveNote,
        getNotesWithId,
        //File Upload & Transactions
        toggleFileUpload,
        fileUpload,
        processTransactions,
        removeTransactionFile,
        getDailyTransactions,
        getStockedAndDelivered,
        toggleErrorReport,
        getErrorReport,
        toggleTransactionReport,
        getTransactionReport,
        //Monthly Transactions
        getAllMonthlyTransactions,
        toggleMonthlyTransactions,
        getMonthlyTransactionsReport,
        //Spoilage
        toggleSpoilage,
        getSpoilage,
        createSpoilage,
        adminReviewSpoilage,
        //Message
        createMessage,
        //Show Message
        toggleShowMessage,
        getMessages,
        markMessageAsRead,
        //Admin
        createAdminOverrirde,
        updateAdminOverride,
        getAdminOverride,
        getAllowedMachines,
        updateAllowedMachines,
        getActiveMachines,
        //Stock Check
        runStockCheck,
        //Stocker Replace Product
        stockerReplaceProduct,
        toggleStockerReplace,
        stockerSelectReplaceProduct,
        //Driver Replace Product
        toggleDriverReplace,
        driverReplaceProduct,
        //Stocker Product Search
        stockerProductSearch,
        getStockerProducts,
        //Special
        updateProductIds,
        //Theme
        toggleThemePreference,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };

// Alerts
export const DISPLAY_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const DISPLAY_CUSTOM_ALERT = 'DISPLAY_CUSTOM_ALERT';
export const CLEAR_CUSTOM_ALERT = 'CLEAR_CUSTOM_ALERT';

//Register and login user
export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN';
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS';
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR';

//Update user
export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

//Toggle side bar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

//User logout
export const LOGOUT_USER = 'LOGOUT_USER';

//Global change handler
export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_VALUES = 'CLEAR_VALUES';

//Create job
export const CREATE_JOB_BEGIN = 'CREATE_JOB_BEGIN';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR';

//Get jobs
export const GET_JOBS_BEGIN = 'GET_JOBS_BEGIN';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';

//Edit job
export const SET_EDIT_JOB = 'SET_EDIT_JOB';
export const EDIT_JOB_BEGIN = 'EDIT_JOB_BEGIN';
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS';
export const EDIT_JOB_ERROR = 'EDIT_JOB_ERROR';

//Delete job
export const DELETE_JOB_BEGIN = 'DELETE_JOB_BEGIN';

//Stats
export const SHOW_STATS_BEGIN = 'SHOW_STATS_BEGIN';
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS';

//Filters
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

//Pagination
export const CHANGE_PAGE = 'CHANGE_PAGE';

//Create product
export const CREATE_PRODUCT_BEGIN = 'CREATE_PRODUCT_BEGIN';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';

//Get products
export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

//Edit products
export const SET_EDIT_PRODUCT = 'SET_EDIT_PRODUCT';
export const EDIT_PRODUCT_BEGIN = 'EDIT_PRODUCT_BEGIN';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_ERROR = 'EDIT_PRODUCT_ERROR';

//Delete product
export const DELETE_PRODUCT_BEGIN = 'DELETE_JOB_BEGIN';

//Create machine
export const CREATE_MACHINE_BEGIN = 'CREATE_MACHINE_BEGIN';
export const CREATE_MACHINE_SUCCESS = 'CREATE_MACHINE_SUCCESS';
export const CREATE_MACHINE_ERROR = 'CREATE_MACHINE_ERROR';

//Get machines
export const GET_MACHINES_BEGIN = 'GET_MACHINES_BEGIN';
export const GET_MACHINES_SUCCESS = 'GET_MACHINES_SUCCESS';

//Edit machine
export const SET_EDIT_MACHINE = 'SET_EDIT_MACHINE';
export const EDIT_MACHINE_BEGIN = 'EDIT_MACHINE_BEGIN';
export const EDIT_MACHINE_SUCCESS = 'EDIT_MACHINE_SUCCESS';
export const EDIT_MACHINE_ERROR = 'EDIT_MACHINE_ERROR';

//Delete machine
export const DELETE_MACHINE_BEGIN = 'DELETE_MACHINE_BEGIN';

//Edit machine activity
export const EDIT_MACHINE_ACTIVITY = 'EDIT_MACHINE_ACTIVITY';

//Set machine
export const SET_MACHINE_BEGIN = 'SET_MACHINE_BEGIN';
export const SET_MACHINE_SUCCESS = 'SET_MACHINE_SUCCESS';
export const SET_MACHINE_ERROR = 'SET_MACHINE_ERROR';

//Add machine product
export const SET_ADD_MACHINE_PRODUCT = 'SET_ADD_MACHINE_PRODUCT';
export const ADD_MACHINE_PRODUCT_BEGIN = 'ADD_MACHINE_PRODUCT_BEGIN';
export const ADD_MACHINE_PRODUCT_SUCCESS = 'ADD_MACHINE_PRODUCT_SUCCESS';
export const ADD_MACHINE_PRODUCT_ERROR = 'ADD_MACHINE_PRODUCT_ERROR';

//Delete machine product
export const DELETE_MACHINE_PRODUCT = 'DELETE_MACHINE_PRODUCT';

//Edit machine product
export const EDIT_MACHINE_PRODUCT_BEGIN = 'EDIT_MACHINE_PRODUCT_BEGIN';
export const EDIT_MACHINE_PRODUCT_SUCCESS = 'EDIT_MACHINE_PRODUCT_SUCCESS';
export const EDIT_MACHINE_PRODUCT_ERROR = 'EDIT_MACHINE_PRODUCT_ERROR';

//Edit current stock
export const EDIT_CURRENT_STOCK_BEGIN = 'EDIT_CURRENT_STOCK_BEGIN';
export const EDIT_CURRENT_STOCK_SUCCESS = 'EDIT_CURRENT_STOCK_SUCCESS';
export const EDIT_CURRENT_STOCK_ERROR = 'EDIT_CURRENT_STOCK_ERROR';

//Edit machine product location
export const EDIT_MACHINE_PRODUCT_LOCATION_BEGIN =
  'EDIT_MACHINE_PRODUCT_LOCATION_BEGIN';
export const EDIT_MACHINE_PRODUCT_LOCATION_SUCCESS =
  'EDIT_MACHINE_PRODUCT_LOCATION_SUCCESS';
export const EDIT_MACHINE_PRODUCT_LOCATION_ERROR =
  'EDIT_MACHINE_PRODUCT_LOCATION_ERROR';

//Remove selected machine
export const REMOVE_SELECTED_MACHINE = 'REMOVE_SELECTED_MACHINE';

//Toggle machine product
export const TOGGLE_MACHINE_PRODUCT = 'TOGGLE_MACHINE_PRODUCT';

//Toggle machine product
export const TOGGLE_MACHINE_NOTE = 'TOGGLE_MACHINE_NOTE';

//Toggle machine add product
export const TOGGLE_MACHINE_ADD_NOTE = 'TOGGLE_MACHINE_ADD_NOTE';

//Set machine status
export const SET_MACHINE_STATUS = 'SET_MACHINE_STATUS';

//Fill selected machine
export const FILL_SELECTED_MACHINE_BEGIN = 'FILL_SELECTED_MACHINE_BEGIN';
export const FILL_SELECTED_MACHINE_SUCCESS = 'FILL_SELECTED_MACHINE_SUCCESS';
export const FILL_SELECTED_MACHINE_ERROR = 'FILL_SELECTED_MACHINE_ERROR';

//Create user
export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

//Get users
export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

//Edit user
export const SET_EDIT_USER = 'SET_USER_MACHINE';
export const EDIT_USER_BEGIN = 'EDIT_USER_BEGIN';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

//Delete user
export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';

//Create note
export const CREATE_NOTE_BEGIN = 'CREATE_NOTE_BEGIN';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

//Get notes
export const GET_NOTES_BEGIN = 'GET_NOTES_BEGIN';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';

//User Actions
export const GET_ACTIONS_BEGIN = 'GET_ACTIONS_BEGIN';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';

//Set machine
export const SET_USER_BEGIN = 'SET_USER_BEGIN';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_ERROR = 'SET_USER_ERROR';

export const TOGGLE_USER_ACTIONS = 'TOGGLE_USER_ACTIONS';

//Delete note
export const DELETE_NOTE_BEGIN = 'DELETE_NOTE_BEGIN';

//Archive note
export const ARCHIVE_NOTE_BEGIN = 'ARCHIVE_NOTE_BEGIN';

//Update Machine Status
export const UPDATE_MACHINE_STATUS = 'UPDATE_MACHINE_STATUS';

//Driver Fill selected machine
export const DRIVER_FILL_SELECTED_MACHINE_BEGIN =
  'DRIVER_FILL_SELECTED_MACHINE_BEGIN';
export const DRIVER_FILL_SELECTED_MACHINE_SUCCESS =
  'DRIVER_FILL_SELECTED_MACHINE_SUCCESS';
export const DRIVER_FILL_SELECTED_MACHINE_ERROR =
  'DRIVER_FILL_SELECTED_MACHINE_ERROR';
export const DRIVER_FILL_SELECTED_MACHINE_ABORT =
  'DRIVER_FILL_SELECTED_MACHINE_SUCCESS';

//File Upload
export const TOGGLE_FILE_UPLOAD = 'TOGGLE_FILE_UPLOAD';

//Other Amount
export const TOGGLE_STOCKER_OTHER_AMOUNT = 'TOGGLE_STOCKER_OTHER_AMOUNT';
export const TOGGLE_DRIVER_OTHER_AMOUNT = 'TOGGLE_DRIVER_OTHER_AMOUNT';

export const FILE_UPLOAD_BEGIN = 'FILE_UPLOAD_BEGIN';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';

//Process Transactions
export const PROCESS_TRANSACTIONS_BEGIN = 'PROCESS_TRANSACTIONS_BEGIN';
export const PROCESS_TRANSACTIONS_SUCCESS = 'PROCESS_TRANSACTIONS_SUCCESS';
export const PROCESS_TRANSACTIONS_ERROR = 'PROCESS_TRANSACTIONS_ERROR';

//Remove transaction file
export const REMOVE_TRANSACTION_FILE_BEGIN = 'REMOVE_TRANSACTION_FILE_BEGIN';
export const REMOVE_TRANSACTION_FILE_SUCCESS =
  'REMOVE_TRANSACTION_FILE_SUCCESS';
export const REMOVE_TRANSACTION_FILE_ERROR = 'REMOVE_TRANSACTION_FILE_ERROR';

//Daily transaction numbers
export const GET_DAILY_TRANSACTIONS_BEGIN = 'GET_DAILY_TRANSACTIONS_BEGIN';
export const GET_DAILY_TRANSACTIONS_SUCCESS = 'GET_DAILY_TRANSACTIONS_SUCCESS';
export const GET_DAILY_TRANSACTIONS_ERROR = 'GET_DAILY_TRANSACTIONS_ERROR';

//Stocked and Delivered
export const GET_STOCKED_DELIVERED_BEGIN = 'GET_STOCKED_DELIVERED_BEGIN';
export const GET_STOCKED_DELIVERED_SUCCESS = 'GET_STOCKED_DELIVERED_SUCCESS';
export const GET_STOCKED_DELIVERED_ERROR = 'GET_STOCKED_DELIVERED_ERROR';

//Error Report
export const TOGGLE_ERROR_REPORT = 'TOGGLE_ERROR_REPORT';

export const GET_ERROR_REPORT_BEGIN = 'GET_ERROR_REPORT_BEGIN';
export const GET_ERROR_REPORT_SUCCESS = 'GET_ERROR_REPORT_SUCCESS';
export const GET_ERROR_REPORT_ERROR = 'GET_ERROR_REPORT_ERROR';

//Transaction Report
export const TOGGLE_TRANSACTION_REPORT = 'TOGGLE_TRANSACTION_REPORT';

export const GET_TRANSACTION_REPORT_BEGIN = 'GET_TRANSACTION_REPORT_BEGIN';
export const GET_TRANSACTION_REPORT_SUCCESS = 'GET_TRANSACTION_REPORT_SUCCESS';
export const GET_TRANSACTION_REPORT_ERROR = 'GET_TRANSACTION_REPORT_ERROR';

//Monthly Sales
export const GET_MONTHLY_TRANSACTIONS_BEGIN = 'GET_MONTHLY_TRANSACTIONS_BEGIN';
export const GET_MONTHLY_TRANSACTIONS_SUCCESS =
  'GET_MONTHLY_TRANSACTIONS_SUCCESS';
export const GET_MONTHLY_TRANSACTIONS_ERROR = 'GET_MONTHLY_TRANSACTIONS_ERROR';

//Toggle show message
export const TOGGLE_SHOW_MESSAGE = 'TOGGLE_SHOW_MESSAGE';

//Create message
export const CREATE_MESSAGE_BEGIN = 'CREATE_MESSAGE_BEGIN';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';

//Get messages
export const GET_MESSAGES_BEGIN = 'GET_MESSAGE_BEGIN';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGE_ERROR';

//Mark message as read
export const MARK_MESSAGE_AS_READ_BEGIN = 'MARK_MESSAGE_AS_READ_BEGIN';
export const MARK_MESSAGE_AS_READ_SUCCESS = 'MARK_MESSAGE_AS_READ_SUCCESS';
export const MARK_MESSAGE_AS_READ_ERROR = 'MARK_MESSAGE_AS_READ_ERROR';

//Create admin override
export const CREATE_ADMIN_OVERRIDE_BEGIN = 'CREATE_ADMIN_OVERRIDE_BEGIN';
export const CREATE_ADMIN_OVERRIDE_SUCCESS = 'CREATE_ADMIN_OVERRIDE_SUCCESS';
export const CREATE_ADMIN_OVERRIDE_ERROR = 'CREATE_ADMIN_OVERRIDE_ERROR';

//Get admin override
export const GET_ADMIN_OVERRIDE_BEGIN = 'GET_ADMIN_OVERRIDE_BEGIN';
export const GET_ADMIN_OVERRIDE_SUCCESS = 'GET_ADMIN_OVERRIDE_SUCCESS';
export const GET_ADMIN_OVERRIDE_ERROR = 'GET_ADMIN_OVERRIDE_ERROR';

//Update admin override
export const UPDATE_ADMIN_OVERRIDE_BEGIN = 'UPDATE_ADMIN_OVERRIDE_BEGIN';
export const UPDATE_ADMIN_OVERRIDE_SUCCESS = 'UPDATE_ADMIN_OVERRIDE_SUCCESS';
export const UPDATE_ADMIN_OVERRIDE_ERROR = 'UPDATE_ADMIN_OVERRIDE_ERROR';

//Update grid view
export const UPDATE_GRID_VIEW = 'UPDATE_GRID_VIEW';

//Show / Hide Product Info View
export const SHOW_HIDE_PRODUCT_INFO_VIEW = 'SHOW_HIDE_PRODUCT_INFO_VIW';

//Update product view
export const UPDATE_PRODUCT_INFO_VIEW = 'UPDATE_PRODUCT_INFO_VIEW';

//Clear selected product
export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';

//Run stock check
export const RUN_STOCK_CHECK_BEGIN = 'RUN_STOCK_CHECK_BEGIN';
export const RUN_STOCK_CHECK_SUCCESS = 'RUN_STOCK_CHECK_SUCCES';
export const RUN_STOCK_CHECK_ERROR = 'RUN_STOCK_CHECK_ERROR';

//Toggle user status
export const TOGGLE_USER_STATUS_BEGIN = 'TOGGLE_USER_STATUS_BEGIN';
export const TOGGLE_USER_STATUS_SUCCESS = 'TOGGLE_USER_STATUS_SUCCESS';
export const TOGGLE_USER_STATUS_ERROR = 'TOGGLE_USER_STATUS_ERROR';

//Add extended registration date
export const ADD_EXTENDED_REGISTRATION_DATE_BEGIN =
  'ADD_EXTENDED_REGISTRATION_DATE_BEGIN';
export const ADD_EXTENDED_REGISTRATION_DATE_SUCCESS =
  'ADD_EXTENDED_REGISTRATION_DATE_SUCCESS';
export const ADD_EXTENDED_REGISTRATION_DATE_ERROR =
  'ADD_EXTENDED_REGISTRATION_DATE_ERROR';

//Stocker replace product
export const STOCKER_REPLACE_PRODUCT_BEGIN = 'STOCKER_REPLACE_PRODUCT_BEGIN';
export const STOCKER_REPLACE_PRODUCT_SUCCESS =
  'STOCKER_REPLACE_PRODUCT_SUCCESS';
export const STOCKER_REPLACE_PRODUCT_ERROR = 'STOCKER_REPLACE_PRODUCT_ERROR';

//Toggle stocker replace
export const TOGGLE_STOCKER_REPLACE = 'TOGGLE_STOCKER_REPLCE';

//Stocker select replace product
export const STOCKER_SELECT_REPLACE_PRODUCT = 'STOCKER_SELECT_REPLACE_PRODUCT';

//Toggle driver replace
export const TOGGLE_DRIVER_REPLACE = 'TOGGLE_DRIVER_REPLACE';

//Driver replace product
export const DRIVER_REPLACE_PRODUCT_BEGIN = 'DRIVER_REPLACE_PRODUCT_BEGIN';
export const DRIVER_REPLACE_PRODUCT_SUCCESS = 'DRIVER_REPLACE_PRODUCT_SUCCESS';
export const DRIVER_REPLACE_PRODUCT_ERROR = 'DRIVER_REPLACE_PRODUCT_ERROR';

//Stocker search products
export const STOCKER_SEARCH_PRODUCTS = 'STOCKER_SEARCH_PRODUCTS';

//Get Stocker Producs
export const GET_STOCKER_PRODUCTS_BEGIN = 'GET_STOCKER_PRODUCTS_BEGIN';
export const GET_STOCKER_PRODUCTS_SUCCESS = 'GET_STOCKER_PRODUCTS_SUCCESS';

//Toggle Monthly Transactions
export const TOGGLE_MONTHY_TRANSACTIONS = 'TOGGlE_MONTHLY_TRANSACTIONS';

//Toggle Spoilage
export const TOGGLE_SPOILAGE = 'TOGGLE_SPOILAGE';

//Get Monthly Transactions Report
export const GET_MONTHLY_TRANSACTIONS_REPORT_BEGIN =
  'GET_MONTHLY_TRANSACTIONS_REPORT_BEGIN';
export const GET_MONTHLY_TRANSACTIONS_REPORT_SUCCESS =
  'GET_MONTHLY_TRANSACTIONS_REPORT_SUCCESS';
export const GET_MONTHLY_TRANSACTIONS_REPORT_ERROR =
  'GET_MONTHLY_TRANSACTIONS_REPORT_ERROR';

//Update Product Ids
export const UPDATE_PRODUCT_IDS_BEGIN = 'UPDATE_PRODUCT_IDS_BEGIN';
export const UPDATE_PRODUCT_IDS_SUCCESS = 'UPDATE_PRODUCT_IDS_SUCCESS';
export const UPDATE_PRODUCT_IDS_ERROR = 'UPDATE_PRODUCT_IDS_ERROR';

//Verify User Role
export const VERIFY_USER_ROLE_BEGIN = 'VERIFY_USER_ROLE_BEGIN';
export const VERIFY_USER_ROLE_SUCCESS = 'VERIFY_USER_ROLE_SUCCES';
export const VERIFY_USER_ROLE_ERROR = 'VERIFY_USER_ROLE_ERROR';

//Get Allowed Machines
export const GET_ALLOWED_MACHINES_BEGIN = 'GET_ALLOWED_MACHINES_BEGIN';
export const GET_ALLOWED_MACHINES_SUCCESS = 'GET_ALLOWED_MACHINES_SUCCESS';
export const GET_ALLOWED_MACHINES_ERROR = 'GET_ALLOWED_MACHINES_ERROR';

//Update Allowed Machines
export const UPDATE_ALLOWED_MACHINES_BEGIN = 'UPDATE_ALLOWED_MACHINES_BEGIN';
export const UPDATE_ALLOWED_MACHINES_SUCCESS =
  'UPDATE_ALLOWED_MACHINES_SUCCESS';
export const UPDATE_ALLOWED_MACHINES_ERROR = 'UPDATE_ALLOWED_MACHINES_ERROR';

//Get Allowed Machines
export const GET_ACTIVE_MACHINES_BEGIN = 'GET_ACTIVE_MACHINES_BEGIN';
export const GET_ACTIVE_MACHINES_SUCCESS = 'GET_ACTIVE_MACHINES_SUCCESS';
export const GET_ACTIVE_MACHINES_ERROR = 'GET_ACTIVE_MACHINES_ERROR';

//Driver Replace Item Flag
export const DRIVER_REPLACE_ITEM_FLAG_BEGIN = 'DRIVER_REPLACE_ITEM_FLAG_BEGIN';
export const DRIVER_REPLACE_ITEM_FLAG_SUCCESS =
  'DRIVER_REPLACE_ITEM_FLAG_SUCCESS';
export const DRIVER_REPLACE_ITEM_FLAG_ERROR = 'DRIVER_REPLACE_ITEM_FLAG_ERROR';

//Get Alerts
export const GET_ALERTS_BEGIN = 'GET_ALERTS_BEGIN';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';

//Archive Alert
export const ARCHIVE_ALERT_BEGIN = 'ARCHIVE_ALERT_BEGIN';
export const ARCHIVE_ALERT_SUCCESS = 'ARCHIVE_ALERT_SUCCESS';
export const ARCHIVE_ALERT_ERROR = 'ARCHIVE_ALERT_ERROR';

//Download Products
export const DOWNLOAD_PRODUCTS_BEGIN = 'DOWNLOAD_PRODUCTS_BEGIN';
export const DOWNLOAD_PRODUCTS_SUCCESS = 'DOWNLOAD_PRODUCTS_SUCCESS';
export const DOWNLOAD_PRODUCTS_ERROR = 'DOWNLOAD_PRODUCTS_ERROR';

//Toggle Independent Pricing
export const TOGGLE_INDEPENDENT_PRICING_BEGIN =
  'TOGGLE_INDEPENDENT_PRICING_BEGIN';
export const TOGGLE_INDEPENDENT_PRICING_SUCCESS =
  'TOGGLE_INDEPENDENT_PRICING_SUCCESS';
export const TOGGLE_INDEPENDENT_PRICING_ERROR =
  'TOGGLE_INDEPENDENT_PRICING_ERROR';

//Toggle Theme
export const TOGGLE_THEME_BEGIN = 'TOGGLE_THEME_BEGIN';
export const TOGGLE_THEME_SUCCESS = 'TOGGLE_THEME_SUCCESS';
export const TOGGLE_THEME_ERROR = 'TOGGLE_THEME_ERROR';

//Spoilage
export const GET_SPOILAGE_BEGIN = 'GET_SPOILAGE_BEGIN';
export const GET_SPOILAGE_SUCCESS = 'GET_SPOILAGE_SUCCESS';
export const GET_SPOILAGE_ERROR = 'GET_SPOILAGE_ERROR';

export const CREATE_SPOILAGE_BEGIN = 'CREATE_SPOILAGE_BEGIN';
export const CREATE_SPOILAGE_SUCCESS = 'CREATE_SPOILAGE_SUCCESS';
export const CREATE_SPOILAGE_ERROR = 'CREATE_SPOILAGE_ERROR';

export const ADMIN_REVIEW_SPOILAGE_BEGIN = 'ADMIN_REVIEW_SPOILAGE_BEGIN';
export const ADMIN_REVIEW_SPOILAGE_SUCCESS = 'ADMIN_REVIEW_SPOILAGE_SUCCESS';
export const ADMIN_REVIEW_SPOILAGE_ERROR = 'ADMIN_REVIEW_SPOILAGE_ERROR';
